<template>
  <!-- eslint-disable max-len -->
  <svg
    viewBox="0 0 24 24">
    <path
      d="M23.5,1h-23C0.224,1,0,1.223,0,1.5v21C0,22.776,0.224,23,0.5,23h23c0.276,0,0.5-0.224,0.5-0.5v-21
      C24,1.223,23.776,1,23.5,1z M23,2v3H1V2H23z M1,22V6h22v16H1z"/>
    <path
      d="M2.5,4c0.13,0,0.25-0.05,0.35-0.15C2.95,3.76,3,3.63,3,3.5S2.94,3.24,2.85,3.15c-0.19-0.2-0.53-0.18-0.7-0.01
      C2.05,3.24,2,3.37,2,3.5s0.05,0.26,0.15,0.35C2.24,3.95,2.37,4,2.5,4z"/>
    <path
      d="M4.5,4c0.13,0,0.25-0.05,0.35-0.15C4.95,3.76,5,3.63,5,3.5S4.95,3.24,4.85,3.15c-0.18-0.19-0.53-0.18-0.7-0.01
      C4.05,3.24,4,3.36,4,3.5c0,0.13,0.05,0.26,0.15,0.35C4.24,3.95,4.37,4,4.5,4z"/>
    <path
      d="M6.5,4c0.13,0,0.26-0.05,0.36-0.15C6.95,3.76,7,3.63,7,3.5S6.95,3.24,6.85,3.15c-0.18-0.19-0.52-0.19-0.71,0
      C6.05,3.24,6,3.37,6,3.5s0.05,0.26,0.14,0.35C6.24,3.95,6.37,4,6.5,4z"/>
    <path
      d="M7.501,15h-4c-0.276,0-0.5,0.224-0.5,0.5v4c0,0.276,0.224,0.5,0.5,0.5h4c0.276,0,0.5-0.224,0.5-0.5v-4
      C8.001,15.223,7.777,15,7.501,15z M7.001,19h-3v-3h3V19z"/>
    <path
      d="M7.501,8h-4c-0.276,0-0.5,0.224-0.5,0.5v4c0,0.276,0.224,0.5,0.5,0.5h4c0.276,0,0.5-0.224,0.5-0.5v-4
      C8.001,8.223,7.777,8,7.501,8z M7.001,12h-3V9h3V12z"/>
    <path
      d="M19.486,9h-8.91c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5h8.91c0.276,0,0.5-0.224,0.5-0.5
      S19.763,9,19.486,9z"/>
    <path
      d="M19.486,16h-8.91c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5h8.91c0.276,0,0.5-0.224,0.5-0.5
      S19.763,16,19.486,16z"/>
    <path
      d="M10.577,12h3.876c0.276,0,0.5-0.224,0.5-0.5s-0.224-0.5-0.5-0.5h-3.876c-0.276,0-0.5,0.224-0.5,0.5
      S10.3,12,10.577,12z"/>
    <path
      d="M14.453,18h-3.876c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5h3.876c0.276,0,0.5-0.224,0.5-0.5
      S14.729,18,14.453,18z"/>
  </svg>
</template>
